const unit = +window
  .getComputedStyle(document.documentElement)
  .getPropertyValue('--unit')
  .replace('px', '');

export default function setHeroSize() {
  const hero = document.querySelector(
    '.hero:not(.hero--plain):not(.hero--post)'
  );
  if (hero) {
    const heroContent = hero.querySelector('.hero__content');
    const gridWidth = hero.querySelector('.dot-grid').offsetWidth;
    const extraWidth = gridWidth % unit;

    // Set width of content to equal the nearest visible bg dot
    heroContent.style.setProperty(
      '--hero-width',
      `${gridWidth - unit * 2 - extraWidth}px`
    );
  }
}

export function setHeroHeight() {
  jQuery(function ($) {
    const $hero_parent = $('.hero--page, .hero--programme');
    const $hero = $hero_parent.find('.hero__content');

    if ($hero.length > 0) {
      var $hero_copy = $hero.find('.hero__copy');
      var height = 0;

      $hero_copy.children('*:visible').each(function () {
        height += $(this).outerHeight(true);
      });

      // 33 is our unit height
      var new_height = Math.round(height / 33) + 5;
      $hero.css('height', 'calc(var(--unit) * ' + new_height + ')');
    }
  });
}

window.addEventListener('resize', () => {
  setTimeout(() => {
    setHeroSize();
    setHeroHeight();
  }, 60);
});

document.addEventListener('DOMContentLoaded', function () {
  setHeroSize();
  setHeroHeight();
});

if (window.acf) {
  window.acf.addAction('render_block_preview', setHeroSize);
}
